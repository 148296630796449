import $ from 'jquery'

$(document).ready(function () {
    $("#video").click(function () {
        $(".lity").removeAttr('hidden');
        $(".lity").removeClass("lity-closed");
        $(".lity").addClass("lity-opened");
        $(".lity-iframe-container").append(
            "<iframe style=\"border: none;\" width=\"853\" height=\"480\" src=\"https://www.youtube.com/embed/IP0cUBWTgpY\" autoplay allowfullscreen></iframe>"
        );
    });
    $('.lity-close').click(function () {
        $(".lity").attr('hidden');
        $(".lity").addClass("lity-closed");
        $(".lity").removeClass("lity-opened");
        $(".lity-iframe-container").text("");
    });
    $(document).mouseup(function (e) {
        var container = $(".lity-iframe-container");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $(".lity").attr('hidden');
            $(".lity").addClass("lity-closed");
            $(".lity").removeClass("lity-opened");
            $(".lity-iframe-container").text("");
        }
    });
    window.onkeyup = function (event) {
        if (event.keyCode == 27) {
            $(".lity").attr('hidden');
            $(".lity").addClass("lity-closed");
            $(".lity").removeClass("lity-opened");
            $(".lity-iframe-container").text("");
        }
    };
});