import $ from "jquery";

function isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    }
    return false
}

$(document).ready(function () {
    "use strict";

    $("nav").ready(function e() {
        L();
        M();
        T();
        Y()
    });

    var e = 400;
    var t = $(window);
    var r = $(document.body);
    var n = $("html");
    var i = $(".c-nav--primary");
    var a = 0;
    var o = $(".c-nav--sidebar");
    var s = $(".c-nav--sidebar__menu");
    var u = $(".c-nav--sidebar__mobileheader.v--main");
    var c = $(".c-nav.v--sticky");
    var f = $(".nav_menu_btn");
    var d = $("#nav_close_btn");
    var l = $("#nav_back_btn");
    var h = $("#nav_workspace_btn");
    var p = $("#nav_work_btn");
    var v = $("#main_nav");
    var _ = $("#workspace_nav");
    var m = $("#workspace_dd");
    var y = false;
    var g = 0;
    var b = 0;
    var w = $("footer.c-nav");
    var M = function e() {
        a = i.height();
        t.on("scroll", S)
    };
    var k = function e(t, r, n) {
        t.addClass(r + " is-animating").one("animationend", function () {
            t.removeClass(r + " is-animating").addClass(n)
        })
    };

    var L = function e() {
        f.on("click", function () {
            b = window.pageYOffset;
            y = true;
            v.removeClass("is-hidden");
            D();
            setTimeout(function () {
                k(v, "slide-in--right", "is-open")
            }, 50)
        });
        d.on("click", function () {
            y = false;
            k(v, "slide-out--right", "is-hidden");
            v.removeClass("is-open");
            r.removeClass("js-nav-open");
            n.removeClass("js-nav-open");
            window.scrollTo(0, b)
        });
        p.on("click", function () {
            m.hasClass("is-open") ? m.removeClass("is-open") : m.addClass("is-open")
        });
        h.on("click", function () {
            _.removeClass("is-hidden");
            setTimeout(function () {
                k(_, "slide-in--right", "is-open")
            }, 50)
        });
        l.on("click", function () {
            k(_, "slide-out--right", "is-hidden");
            _.removeClass("is-open")
        });
        r.on("click", function (e) {
            $(e.target).is(p) || m.removeClass("is-open")
        });
        t.on("resize", x)
    };
    var S = function t() {
        if (!isMobile() && this.pageYOffset >= e && !r.hasClass("is-scrolled")) {
            r.addClass("is-scrolled");
            i.addClass("is-fixed")
        } else if (!isMobile() && this.pageYOffset < e && r.hasClass("is-scrolled")) {
            r.removeClass("is-scrolled");
            i.addClass("is-detached");
            setTimeout(function () {
                i.removeClass("is-fixed is-detached")
            }, 50)
        }
        c.each(function () {
            var e = $(this).offset().top - a;
            if (window.pageYOffset > e && !$(this).data("clone").hasClass("is-stuck")) {
                $(this).data("clone").show().addClass("is-stuck");
                i.addClass("has-sticky-child")
            } else if (window.pageYOffset < e && $(this).data("clone").hasClass("is-stuck")) {
                $(this).data("clone").hide().removeClass("is-stuck");
                i.removeClass("has-sticky-child")
            }
        });
        if (g >= window.pageYOffset) {
            r.addClass("is-up-scroll");
            r.removeClass("is-down-scroll")
        } else {
            r.removeClass("is-up-scroll");
            r.addClass("is-down-scroll")
        }
        w.length && ($(window).scrollTop() + $(window).height() >= w.offset().top ? r.addClass("footer-visible") : r.removeClass("footer-visible"));
        g = window.pageYOffset
    };
    var T = function e() {
        s.each(function () {
            var e = $(this).find(".c-nav--sidebar__mobileheader");
            e.on("click", function () {
                $(this).toggleClass("is-selected")
            })
        });
        u.on("click", function () {
            $(this).toggleClass("is-selected");
            $("body").toggleClass("u-noscroll-on-mobile")
        });
        o.on("focusout", function (e) {
            if (!$(e.relatedTarget).closest(o).length) {
                u.removeClass("is-selected");
                $("body").toggleClass("u-noscroll-on-mobile")
            }
        })
    };
    var Y = function e() {
        c.each(function () {
            var e = $(this).clone().hide();
            e.data("ancestor", $(this));
            $(this).data("clone", e);
            e.insertAfter($(this))
        })
    };
    var x = function e() {
        D()
    };
    var D = function e() {
        if (t.width() <= 1084 && y) {
            r.addClass("js-nav-open");
            n.addClass("js-nav-open")
        } else {
            r.removeClass("js-nav-open");
            n.removeClass("js-nav-open")
        }
    }
});
$(document).ready(function () {
    $("#nav_solutions_btn").mouseover(function () {
        $("#solutions_dd").show();
    });
    $("#nav_solutions_btn").mouseleave(function () {
        $("#solutions_dd").hide();
    });
});